import { useEffect, useState } from 'react';
import instance from '../../utils/instance';
import { useParams, useNavigate } from 'react-router-dom';
import { ORDERINFO } from '../../config/endPoints';
import Loader from '../common/Loader';
import GoogleMapReact from 'google-map-react';
import { FaDotCircle, FaCheckCircle } from 'react-icons/fa';
// import { FaDotCircle } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import './order.css';
import { LocationMarkerIcon } from '@heroicons/react/solid';
const OrderStatus = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const { transactionId } = useParams();
  const navigate = useNavigate();

  const fetchOrderDetails = async () => {
    if (!transactionId)
      return (
        <div>
          <h1>Order ID is required to view this page.</h1>
          <button onClick={() => (window.location.href = 'https://curiobit.ai/')}>Go Back</button>
        </div>
      );
    try {
      const { data } = await instance.get(`${ORDERINFO}${transactionId}`);
      setOrderDetails(data);
    } catch (error) {
      console.error('Error fetching order details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!transactionId) {
      navigate('/test/curiobit', { replace: true });
    }
  }, [transactionId, navigate]);

  useEffect(() => {
    fetchOrderDetails();
  }, [transactionId]);

  const renderMap = (latitude, longitude) => {
    return (
      <div className="h-60 w-full rounded-md shadow-md">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
          defaultCenter={{ lat: latitude, lng: longitude }}
          defaultZoom={15}
        >
          <div data-lat={latitude} data-lng={longitude} className="font-bubble-sans text-red-500">
            <LocationMarkerIcon className="size-6" /> Delivery Address
          </div>
        </GoogleMapReact>
      </div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  const { _id, bookingDetails, deliveryStatus, transactionDetails } = orderDetails || {};
  const deliveryAddress = bookingDetails?.extraQuestions?.find((q) => q.questionCode === 'ADD')?.answer;
  const latitude = deliveryAddress?.lat || 0;
  const longitude = deliveryAddress?.lng || 0;

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(dateTimeString);
    return date.toLocaleDateString('en-US', options);
  };
  const steps = ['CONFIRMED', 'PROCESSED', 'INTRANSIT', 'DELIVERED'];
  return (
    <div className="flex flex-col items-center px-5 py-10">
      <h1 className="mb-6 text-3xl font-extrabold text-white">Order Status</h1>

      <div className="flex w-full max-w-3xl flex-row justify-between gap-10">
        <div className="mb-2 text-white">
          <span className="font-bold uppercase">Order Id:</span> {_id || 'N/A'}
        </div>

        <div className="mb-2 text-white">
          <span className="font-bold uppercase">Ordered on:</span>{' '}
          {transactionDetails?.bookingTime ? formatDateTime(transactionDetails.bookingTime) : 'N/A'}
        </div>
      </div>
      <div className="mb-6 w-full max-w-3xl rounded-lg bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-xl font-semibold">Delivery Progress</h2>
        <div className="flex w-full items-center justify-between">
          {steps.map((step, index) => {
            const isCompleted = steps.indexOf(deliveryStatus) >= index;
            const isLastStep = index === steps.length - 1;
            return (
              <div key={step} className="delivery-step relative flex items-center">
                <div className="flex flex-col items-center">
                  {isCompleted ? (
                    <FaCheckCircle className="text-3xl text-green-500" />
                  ) : (
                    <FaDotCircle className="text-3xl text-gray-300" />
                  )}
                  <span className="mt-2 text-sm">{step.replace('_', ' ')}</span>
                </div>
                {!isLastStep && (
                  <div
                    className={`absolute bottom-10 left-[50px] h-1 ${
                      isCompleted && index === 2
                        ? '-ml-1 w-[68px] bg-green-500 md:w-[180px] lg:w-[188px]'
                        : isCompleted
                        ? 'w-[68px] bg-green-500 md:w-[180px] lg:w-[195px]'
                        : index === 2
                        ? '-ml-1 w-[60px] bg-gray-300 md:w-[168px] lg:w-[186px]'
                        : index === 1
                        ? 'ml-1 w-[60px] bg-gray-300 md:w-[168px] lg:w-[186px]'
                        : 'w-[60px] bg-gray-300 md:w-[168px] lg:w-[186px]'
                    }`}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="mb-6 w-full max-w-3xl rounded-lg bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-xl font-semibold">Delivery Address</h2>
        <div className="mb-4 text-gray-700">
          {deliveryAddress ? (
            <>
              <p>{deliveryAddress.name}</p>
              <p>
                {deliveryAddress.addressLine1}, {deliveryAddress.addressLine2}, {deliveryAddress.city},{' '}
                {deliveryAddress.state}, {deliveryAddress.pincode}, {deliveryAddress.country}
              </p>
            </>
          ) : (
            'N/A'
          )}
        </div>
        {latitude && longitude ? <div className="map-container">{renderMap(latitude, longitude)}</div> : null}
        {/* {latitude && longitude ? renderMap(latitude, longitude) : null} */}
      </div>

      <div className="mb-6 w-full max-w-3xl rounded-lg bg-white p-6 shadow-lg">
        <div
          className=" flex cursor-pointer items-center justify-between"
          onClick={() => setShowBookingDetails(!showBookingDetails)}
        >
          <h2 className="text-xl font-semibold">Booking Details</h2>
          {showBookingDetails ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
        {showBookingDetails && (
          <div className="mt-4 text-gray-700">
            <div className="mb-2">
              <span className="font-semibold">Book Name:</span> {bookingDetails?.ticketTypeArray[0]?.name || 'N/A'}
            </div>
            <div className="mb-2">
              <span className="font-semibold">Total Quantity:</span>{' '}
              {bookingDetails?.ticketTypeArray[0]?.quantity || 'N/A'}
            </div>
          </div>
        )}
      </div>

      <div className="mb-6 w-full max-w-3xl rounded-lg bg-white p-6 shadow-lg">
        <div
          className="flex cursor-pointer items-center justify-between"
          onClick={() => {
            setShowOrderSummary(!showOrderSummary);
          }}
        >
          <h2 className="text-xl font-semibold">Order Summary</h2>
          {showOrderSummary ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
        {showOrderSummary && (
          <div className="mt-4 text-gray-700">
            <div className="mb-2">
              <span className="font-semibold">Book(s) Subtotal:</span> ₹{' '}
              {transactionDetails?.transaction?.originalAmount || 'N/A'}
            </div>
            <div className="mb-2">
              <span className="font-semibold">Shipping:</span> ₹ {transactionDetails?.transaction?.deliveryFee || 'N/A'}
            </div>
            <div className="mb-2">
              <span className="font-semibold">Grand Total:</span> ₹{' '}
              {transactionDetails?.transaction?.paymentAmount || 'N/A'}
            </div>
          </div>
        )}
      </div>

      <button
        onClick={() => (window.location.href = 'https://curiobit.ai/')}
        className="back-home-button rounded-md bg-gray-600 px-4 py-2 text-white transition duration-200 hover:bg-gray-700"
      >
        Back to Curiobit
      </button>
    </div>
  );
};

export default OrderStatus;
