import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { WEB_TYPE } from '../config/endPoints';
import { getInfoEvent } from '../redux/actions/eventActions';
import { getUserProfile } from '../redux/actions/userActions';

export const useWebViewHandle = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const match = matchPath('/:code/:token/:type', pathname);
  const matchParams = match?.params;
  let eventCodeFromParams = null;
  if (
    matchParams?.code &&
    matchParams?.code !== 'error' &&
    matchParams?.code !== 'organiser' &&
    matchParams?.code !== 'booking' &&
    matchParams?.code !== 'test' &&
    matchParams?.code !== 'curiobit' &&
    matchParams?.code !== '404' &&
    matchParams?.code !== 'category' &&
    matchParams?.code !== 'allevents' &&
    matchParams?.code !== 'search' &&
    matchParams?.code !== 'profile' &&
    matchParams?.code !== 'mybooking' &&
    matchParams?.code !== 'ticket'
  ) {
    eventCodeFromParams = matchParams.code;
  }
  const userToken = matchParams?.token;
  const webType = matchParams?.type;

  useEffect(() => {
    const webViewHandle = async () => {
      if (eventCodeFromParams && userToken && webType === WEB_TYPE) {
        if (typeof window !== 'undefined') {
          await localStorage.setItem('token', userToken);
        }
        await dispatch(getUserProfile(userToken));
        await dispatch(getInfoEvent(eventCodeFromParams));
      }
    };
    webViewHandle();
  }, [eventCodeFromParams, userToken, webType]);
  return webType;
};
