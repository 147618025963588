import { useEffect, useState } from 'react';
import instance from '../../utils/instance';
import { useParams, useNavigate } from 'react-router-dom';
import successGif from '../../Images/curiobit/success.gif';
import { PAYMENT } from '../../config/endPoints';
import Loader from '../common/Loader';

const Product_Success = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { orderId } = useParams();
  const navigate = useNavigate();

  const fetchPaymentStatus = async () => {
    try {
      const { data } = await instance.get(`${PAYMENT}${orderId}`);
      setPaymentDetails(data);
    } catch (error) {
      console.error('Error fetching payment status:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!orderId) {
      navigate('/test/curiobit', { replace: true });
      return;
    }
    fetchPaymentStatus();
  }, [orderId, navigate]);

  useEffect(() => {
    let interval;
    if (paymentDetails?.transactionPending) {
      interval = setInterval(fetchPaymentStatus, 4000);
    }
    return () => clearInterval(interval);
  }, [paymentDetails?.transactionPending]);

  if (loading) {
    return <Loader />;
  }

  const paymentSuccessful = paymentDetails?.transactionSuccessful;
  const transactionId = paymentDetails?.transactionId || orderId;
  const paymentAmount = paymentDetails?.bookingDetails?.paymentAmount || 'N/A';
  const paymentStatus = paymentDetails?.userText || 'N/A';

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-black px-5">
      {paymentSuccessful ? (
        <>
          <img src={successGif} alt="Success" className="mb-6 w-[60px] animate-bounce" />
          <h1 className="mb-4 text-3xl font-bold text-green-400">Order Successfully Placed!</h1>
          <div className="mb-6 rounded-md border border-green-400 bg-green-500 px-5 py-3 text-white" role="alert">
            <strong>Congratulations!</strong> Your book has been successfully booked.
          </div>
          <div className="w-full max-w-md rounded-lg bg-gray-800 p-6 shadow-lg">
            <h2 className="mb-3 text-xl font-semibold text-white">Transaction Details</h2>
            <div className="mb-2 text-gray-300">
              <span className="font-medium">Transaction ID:</span> {transactionId}
            </div>
            <div className="mb-2 text-gray-300">
              <span className="font-medium">Amount Paid:</span> Rs {paymentAmount}
            </div>
            <div className="mb-2 text-gray-300">
              <span className="font-medium">Payment Status:</span> {paymentStatus}
            </div>
            <div className="mt-4 flex space-x-4">
              <button
                onClick={() => (window.location.href = 'https://curiobit.ai/')}
                // onClick={() => navigate('/curiobit')}
                className="z-30 rounded-md bg-gray-600 px-4 py-2 text-white transition duration-200 hover:bg-gray-700"
              >
                Back to Home
              </button>
              <button
                onClick={() => navigate(`/test/curiobit/orderstatus/${transactionId}`)}
                className="rounded-md bg-blue-600 px-4 py-2 text-white transition duration-200 hover:bg-blue-700"
              >
                View Order Status
              </button>
            </div>
            <div className="mt-4 text-center text-sm text-gray-400">
              <span className="font-medium text-red-500">Powered by FYDO</span>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center text-red-500">
          <h1 className="mb-4 text-3xl font-bold">Payment Failed</h1>
          <p className="mb-4">
            We couldn&apos;t retrieve your payment details. Please try again later or contact support.
          </p>
          <button
            onClick={() => navigate('/test/curiobit')}
            className="rounded-md bg-gray-600 px-4 py-2 text-white transition duration-200 hover:bg-gray-700"
          >
            Back to Product Page
          </button>
          <div className="mt-4 text-center text-sm text-gray-400">
            <span className="font-medium text-red-500">Powered by FYDO</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product_Success;
