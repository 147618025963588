import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { connect, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { clearErrors } from '../../redux/actions/userActions';
import { addNewAnswer } from '../../redux/actions/eventActions';
import { FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import SelectField from '../common/SelectField';
import TextField from '../../utils/TextField';
const SelectTextBox = (item, index, setFieldValue, values) => {
  switch (item.answerType) {
    case 'DROP_DOWN':
      return (
        <SelectField
          label={item.questionText}
          placeholder={item?.questionText}
          item={item?.answerOptions}
          key={`110${index}`}
          name={`answer.${index}.select`}
          required={item?.mandatory}
        />
      );
    case 'DONATION_DROPDOWN':
      return (
        <SelectField
          label={item.questionText}
          placeholder={item?.questionText}
          item={item?.answerOptions}
          key={`110${index}`}
          name={`answer.${index}.select`}
          required={item?.mandatory}
        />
      );
    case 'DONATION':
      return (
        <TextField
          label={item.questionText}
          placeholder={item?.questionText}
          item={item?.answerOptions}
          name={`answer.${index}.select`}
          key={`120${index}`}
          type="number"
          min={0}
          required={item?.mandatory}
        />
      );
    case 'TEXT':
      return (
        <TextField
          label={item.questionText}
          placeholder={item?.questionText}
          item={item?.answerOptions}
          name={`answer.${index}.select`}
          key={`130${index}`}
          type="text"
          required={item?.mandatory}
        />
      );
    case 'NUMERIC':
      return (
        <TextField
          label={item.questionText}
          placeholder={item?.questionText}
          item={item?.answerOptions}
          name={`answer.${index}.select`}
          key={`140${index}`}
          type="number"
          min={0}
          required={item?.mandatory}
        />
      );
    case 'PHONE_NUMBER':
      return (
        <TextField
          label={item.questionText}
          placeholder={item?.questionText}
          name={`answer.${index}.select`}
          key={`170${index}`}
          type="tel"
          pattern="[0-9]{10}"
          required={item?.mandatory}
        />
      );
    case 'EMAIL':
      return (
        <TextField
          label={item.questionText}
          placeholder={item?.questionText}
          name={`answer.${index}.select`}
          key={`180${index}`}
          type="email"
          required={item?.mandatory}
        />
      );
    case 'DATE':
      return (
        <TextField
          label={item.questionText}
          placeholder={item?.questionText}
          name={`answer.${index}.select`}
          key={`190${index}`}
          type="date"
          required={item?.mandatory}
        />
      );
    case 'MULTIPLE_CHOICE':
      return (
        <div key={`150${index}`}>
          {item?.questionText && (
            <label className="mb-2 block whitespace-pre-line text-sm font-medium text-gray-900">
              {item?.questionText || ''}
            </label>
          )}
          <div className="grid grid-cols-2 gap-4">
            {item?.answerOptions?.map((option, optionIndex) => (
              <div className="flex items-center gap-2" key={`160${optionIndex}`}>
                <input
                  id={`checkbox-${index}-${optionIndex}`}
                  name={`answer.${index}.select`}
                  type="checkbox"
                  value={option}
                  checked={values.answer[index]?.select.includes(option)}
                  onChange={(e) => {
                    let selectedOptions = values.answer[index]?.select || '';
                    if (e.target.checked) {
                      selectedOptions += selectedOptions ? `,${option}` : option;
                    } else {
                      selectedOptions = selectedOptions
                        .split(',')
                        .filter((selectedOption) => selectedOption !== option)
                        .join(',');
                    }
                    setFieldValue(`answer.${index}.select`, selectedOptions);
                  }}
                  className="size-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
                />
                <label htmlFor={`checkbox-${index}-${optionIndex}`} className="ms-2 text-sm font-medium text-gray-900">
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>
      );
    default:
      break;
  }
};
const QuestionsDialog = ({ isOpen, handleClose, eventInfo }) => {
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const INITIAL_FORM_STATE = {
    answer: [],
  };
  const FORM_VALIDATION = Yup.object().shape({
    answer: Yup.array().of(
      Yup.object({
        select: Yup.string().typeError('Please enter a answer'),
      }),
    ),
  });
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => {}}>
        <div className="flex min-h-screen items-center justify-center p-0 px-4 pb-20 pt-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative size-full max-w-md p-4 md:h-auto">
              {/* Modal content */}
              <div className="relative rounded-lg bg-white shadow">
                <button
                  type="button"
                  className="absolute right-2.5 top-3 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
                  onClick={() => {
                    dispatch(clearErrors());
                    handleClose();
                  }}
                >
                  <svg
                    aria-hidden="true"
                    className="size-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 lg:px-8">
                  <Formik
                    initialValues={{
                      ...INITIAL_FORM_STATE,
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={async (values, { resetForm }) => {
                      const newData = eventInfo?.extraQuestions.map((item, index) => {
                        let answerValue = values?.answer[index]?.select;
                        if (item.answerType === 'DONATION_DROPDOWN') {
                          answerValue = values?.answer[index]?.select ?? '0 - demo';
                          answerValue = answerValue?.split('-')[0].trim();
                        }
                        return {
                          questionCode: item.questionCode,
                          questionText: item.questionText,
                          answerType: item.answerType,
                          answer: answerValue,
                        };
                      });
                      await dispatch(addNewAnswer(newData));
                      resetForm({ values: '' });
                      handleClose();
                    }}
                  >
                    {({ values, setFieldValue }) => (
                      <Form className="space-y-6 whitespace-pre-line">
                        <FieldArray name="answer">
                          <React.Fragment>
                            {eventInfo?.extraQuestions?.map((item, index) =>
                              SelectTextBox(item, index, setFieldValue, values),
                            )}
                          </React.Fragment>
                        </FieldArray>
                        <button
                          type="submit"
                          className="w-full rounded-lg bg-rose-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-rose-600 focus:outline-none focus:ring-4 focus:ring-blue-300 "
                        >
                          {'Submit'}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
const mapStateToProps = (state) => ({
  eventInfo: get(state, 'event.eventInfo', {}),
});
export default connect(mapStateToProps)(QuestionsDialog);
