import React, { useRef, useEffect } from 'react';
import { ErrorMessage, useField } from 'formik';

const TextField = ({ label, inputFocus, type = 'text', ...props }) => {
  const [field, meta] = useField(props);
  const inputReference = useRef(null);
  useEffect(() => {
    if (inputFocus) {
      inputReference.current.focus();
    }
  }, [inputFocus]);
  return (
    <div>
      {label && (
        <label htmlFor={field.name} className="mb-2 block text-sm font-medium text-gray-900">
          {label}
        </label>
      )}
      <input
        className={`block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 ${
          meta.touched && meta.error && 'border-red-500'
        }`}
        {...field}
        {...props}
        autoComplete="off"
        ref={inputReference}
        type={type}
      />
      <ErrorMessage className="text-sm text-red-500" component={'p'} name={field.name} />
    </div>
  );
};

export default TextField;
