import OrderStatus from '../components/Product/OrderStatus';
import Footer from '../components/Product/Footer';
import p1 from '../Images/curiobit/p1.png';
import p2 from '../Images/curiobit/p2.png';
import p3 from '../Images/curiobit/p3.png';
import logo from '../Images/curiobit/logo-light.png';

const OrderPage = () => {
  const demo = 'demo';
  return (
    <div className="bg-[#020608]">
      <div className="mx-auto flex max-w-[1300px] flex-row items-center justify-between bg-transparent px-10 py-4 font-montserrat font-semibold text-[#FDF4DF] xl:px-0">
        <div>
          <img src={logo} alt="logo" className="h-[20px] w-[80px] cursor-pointer md:h-[30px] md:w-[120px]" />
          <h6 className="w-[100%] font-montserrat text-sm font-normal leading-6 tracking-wide text-white">
            A bit of curiosity
          </h6>
        </div>
      </div>
      <div className="relative">
        <img
          src={p1}
          alt="hestar"
          className="absolute -bottom-[42px] left-0 size-[300px] md:size-[400px] lg:size-[700px]"
        />
        <img
          src={p2}
          alt="hestar"
          className="absolute -top-[28px] left-0 size-[200px] md:size-[400px] lg:size-[700px]"
        />
        <img
          src={p3}
          alt="hestar"
          className="absolute -bottom-32 right-0 size-[200px] md:size-[400px] md:h-[600px] lg:w-[600px]"
        />

        <OrderStatus />
      </div>
      <Footer demo={demo} />
    </div>
  );
};

export default OrderPage;
