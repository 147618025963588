import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { getInfoEvent } from '../redux/actions/eventActions';

export const useCodeHandle = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const match = matchPath('/:code/*', pathname);
  const matchParams = match?.params;
  let eventCodeFromParams = null;
  if (
    matchParams?.code &&
    matchParams?.code !== 'error' &&
    matchParams?.code !== 'booking' &&
    matchParams?.code !== 'organiser' &&
    matchParams?.code !== 'test' &&
    matchParams?.code !== 'curiobit' &&
    matchParams?.code !== '404' &&
    matchParams?.code !== 'category' &&
    matchParams?.code !== 'allevents' &&
    matchParams?.code !== 'search' &&
    matchParams?.code !== 'profile' &&
    matchParams?.code !== 'mybooking' &&
    matchParams?.code !== 'ticket'
  ) {
    eventCodeFromParams = matchParams.code;
  }
  useMemo(() => {
    if (eventCodeFromParams) {
      dispatch(getInfoEvent(eventCodeFromParams));
    }
  }, [eventCodeFromParams]);
};
