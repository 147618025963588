import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const CustomerRoute = () => {
  const { loading, token, user } = useSelector((state) => state?.auth);
  const { eventInfo } = useSelector((state) => state?.event);
  if (loading === false && isEmpty(token) && isEmpty(user) && !user?.isAuthenticated) {
    if (!isEmpty(eventInfo)) {
      return <Navigate to={`/curiobit`} replace />;
    }
    return <Navigate to={`/`} replace />;
  }
  return <Outlet />;
};
export default CustomerRoute;
