import React, { useState, memo } from 'react';
import backgroundImage from '../../Images/booking/btnBg.png';
import { useNavigate, useLocation } from 'react-router-dom';
import Checkout from './Checkout/Checkout';
import { ticketPrices } from '../../data';
import { CTImage } from '../common';
import { isEmpty, truncate } from 'lodash';
import parse from 'html-react-parser';
import { CTWrapper } from '../common';
import { TbTimeDuration30 } from 'react-icons/tb';
import { GrCalendar, GrLanguage, GrUser } from 'react-icons/gr';
import { BiCategory } from 'react-icons/bi';
import { PiClock } from 'react-icons/pi';
//import fgImage from '../../Images/booking/coupon.jpg'

import { connect } from 'react-redux';
import { home } from '../../data';
import { get } from 'lodash';
import LocationMap from './Map';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { BsFacebook, BsInstagram, BsTwitter, BsWhatsapp } from 'react-icons/bs';
import { RiCoupon3Fill } from 'react-icons/ri';
// import ReactMarkdown from 'react-markdown';
// import turndown from 'turndown';

const Info = ({ text, icon }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="rounded-md bg-btnPrimary p-2 text-white ">
        <span className="size-[12px] md:size-[18px]">{icon}</span>
      </div>
      <p className="font-semibold leading-4 tracking-normal text-secondary sm:text-xs mm:text-sm">{text}</p>
    </div>
  );
};

const TNCCoupon = ({ desc, tnc, handleClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="w-3/4 rounded-lg bg-white shadow-xl sm:w-1/2 md:max-w-lg">
        <div className="rounded-t-lg bg-btnPrimary px-4 py-2 text-white">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold">Coupon Information</h2>
            <button onClick={handleClose} className="text-white hover:text-gray-200 focus:outline-none">
              <svg
                className="size-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div className="p-4">
          <h2 className="text-base font-semibold">Description</h2>
          <p className="text-sm text-gray-700">{desc}</p>
          <h2 className="mt-4 text-base font-semibold">Terms and Conditions</h2>
          <p className="text-sm text-gray-700">{tnc}</p>
        </div>
        <div className="flex justify-end rounded-b-lg bg-gray-200 p-4">
          <button
            onClick={handleClose}
            className="rounded-md bg-btnPrimary px-4 py-2 text-white hover:bg-gray-700 focus:outline-none"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const buttonStyle = {
  backgroundImage: `url(${backgroundImage})`,
};

const BookHeader = ({
  poster,
  name,
  duration,
  eventTime,
  dateString,
  contentType,
  language,
  location,
  categoryDisplay,
  description,
  subdescription,
  ticketPriceDisplay,
  eventAddress,
  artistImageUrl,
  artistName,
  artistDescription,
  artistInstaUrl,
  artistFacebookUrl,
  artistTwitterUrl,
  policies,
  event,
  handleOpen,
  eventInfo,
  eventVideoLink,
}) => {
  const [showFullText, setShowFullText] = useState(false);
  const [showFT, setShowFT] = useState(Array(policies.length).fill(false));
  const [popupVisible, setPopupVisible] = useState(false);
  const copyCouponCode = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        // console.log('Coupon code copied to clipboard:', code);
        setPopupVisible(true);
        setTimeout(() => {
          setPopupVisible(false);
        }, 3000);
      })
      .catch((error) => {
        console.error('Failed to copy coupon code:', error);
      });
  };
  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const handleGetTicketsClick = () => {
    setIsCheckoutOpen(true);
  };
  const history = useNavigate();

  const toggleRM = (index) => {
    const updatedShowFullText = [...showFT];
    updatedShowFullText[index] = !updatedShowFullText[index];
    setShowFT(updatedShowFullText);
  };
  const eventpolicy = !isEmpty(eventInfo.policies) && eventInfo ? eventInfo?.policies : ticketPrices?.policies;
  const coupons = !isEmpty(eventInfo.eventCoupons) && eventInfo?.eventCoupons;
  const sharelocation = useLocation();
  const eventUrl = `${window.location.origin}${sharelocation.pathname}`;
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDesc, setSelectedDesc] = useState('');
  const [selectedTNC, setSelectedTNC] = useState('');
  const handleGetTNCClick = (desc, tnc) => {
    setSelectedDesc(desc);
    setSelectedTNC(tnc);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedDesc('');
    setSelectedTNC('');
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          text: `Check out this event ${eventInfo.name} happening at ${eventInfo.eventAddress}`,
          url: eventUrl,
        });
      } else {
        console.log('Web Share API not supported');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };
  let imageUrl;
  if (!isEmpty(poster) && poster[0]?.url) {
    imageUrl = poster[0].url;
  } else {
    imageUrl = GreetFydo;
  }
  const handleViewTicket = () => {
    history(`/${eventInfo?.eventCode}/mybooking`);
  };
  return (
    <>
      <CTWrapper>
        <div className="flex size-full flex-col gap-5 py-4 md:flex-row md:gap-5 md:py-8 lg:gap-10">
          <div className=" flex flex-col gap-8 sm:w-full md:w-[512px] lg:w-[811px] ">
            <div className="flex flex-col gap-3 pb-[20px]">
              <div>
                {eventVideoLink ? (
                  <iframe
                    src={`https://www.youtube.com/embed/${eventVideoLink}`}
                    title={name && name}
                    allowFullScreen
                    className={' aspect-[18/10] w-full  rounded-xl md:aspect-[25/10]'}
                  />
                ) : (
                  <CTImage
                    src={imageUrl}
                    alt={name && name}
                    className={'aspect-[20/10] rounded-xl	shadow-lg md:aspect-[20/10]'}
                  />
                )}
              </div>
              {/* const coupons = !isEmpty(eventInfo.eventCoupons) && eventInfo.eventCoupons; */}

              <div className="mx-2 flex flex-col gap-3 text-left lg:grid lg:grid-cols-2 ">
                {coupons &&
                  coupons.length > 0 &&
                  coupons.map((item, index) =>
                    // Only render the coupon if it's active
                    item.isActive ? (
                      <div
                        key={index}
                        className="flex w-full flex-col rounded-lg bg-rose-50 p-2 shadow-xl md:w-[400px] lg:w-[28x0px] lg:p-4 xl:w-[380px]"
                      >
                        <div className="flex flex-row">
                          <RiCoupon3Fill className="mr-4 size-10 rounded-full bg-btnPrimary p-2 text-white" />
                          <div className="flex w-[80%] flex-col gap-1">
                            <p className="w-[95%] text-wrap text-sm font-semibold text-[#222222] hover:text-btnPrimary">
                              {item?.name}
                            </p>
                            <div className="tracking-wide text-[#475467] sm:text-xs">
                              <button
                                onClick={() => handleGetTNCClick(item?.desc, item?.tnc)}
                                className="text-xs font-medium text-rose-600"
                              >
                                {truncate(item?.desc, { length: 40 })} <u>More</u>
                              </button>
                            </div>
                          </div>
                          <div className="item-center flex w-[20%] justify-center">
                            <button
                              className="cursor-pointer border-l-2 border-dotted border-rose-600 pl-3 text-sm font-semibold text-rose-600 md:right-0"
                              onClick={() => copyCouponCode(item?.code)}
                            >
                              COPY
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null,
                  )}
                {showPopup && <TNCCoupon desc={selectedDesc} tnc={selectedTNC} handleClose={handleClosePopup} />}

                {/* Popup Message */}
                {popupVisible && (
                  <div className="mx-auto">
                    <div className="fixed inset-x-0 top-20 mx-auto w-[300px] items-center justify-center rounded-md bg-btnPrimary p-3 text-center text-white shadow-md">
                      Coupon code copied to clipboard!
                    </div>
                  </div>
                )}
              </div>

              <div className="md:hidden">
                <div className={`overflow-y-hidden'} flex flex-col gap-4`}>
                  <div className="flex flex-col gap-2 rounded-lg border border-gray-200 p-4 md:gap-4">
                    <p className="text-xl font-bold leading-8 tracking-wide  text-[#101828] lg:text-3xl">
                      {name && name}
                    </p>
                    <div className="mx-2 border-b  border-gray-200" />
                    <div className="mb-1 grid grid-cols-2 gap-1 mm:gap-2 md:gap-4">
                      {duration && <Info text={duration && duration} icon={<TbTimeDuration30 />} />}
                      {eventTime && <Info text={eventTime && eventTime} icon={<PiClock />} />}
                      {dateString && <Info text={dateString && dateString} icon={<GrCalendar />} />}
                      {contentType && <Info text={contentType && contentType} icon={<GrUser />} />}
                      {language && <Info text={language && language} icon={<GrLanguage />} />}
                      {categoryDisplay && <Info text={categoryDisplay && categoryDisplay} icon={<BiCategory />} />}
                    </div>
                    {subdescription && (
                      <div className="leading-5 tracking-wide text-secondary sm:text-xs mm:text-sm mm:leading-5">
                        {parse(subdescription)}
                      </div>
                    )}

                    <button
                      className="fixed bottom-0 left-0 z-20 flex w-full items-center justify-between gap-8 bg-btnPrimary bg-cover px-4 py-2 text-white md:hidden"
                      onClick={handleGetTicketsClick}
                      style={buttonStyle}
                    >
                      <div className="flex flex-col items-start justify-start">
                        <p className="text-xs font-medium text-[#FFA9A9] ">STARTING FROM</p>
                        <p className="text-nowrap text-base font-semibold uppercase leading-5 tracking-tight">
                          {ticketPriceDisplay && ticketPriceDisplay}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="text-nowrap text-sm font-medium leading-5 text-white"> Get Tickets</p>
                        <ArrowCircleRightIcon className="size-6" />
                      </div>
                    </button>

                    {event?.bookingDone && (
                      <button
                        className="flex w-full items-center justify-center gap-8 bg-btnPrimary bg-cover px-4 py-2 text-center text-white md:hidden"
                        onClick={handleViewTicket}
                        style={buttonStyle}
                      >
                        {home.button3}
                      </button>
                    )}
                  </div>

                  <div className="flex flex-col gap-4 rounded-lg border border-gray-200 p-3 md:p-4">
                    <LocationMap location={location} />

                    <div className="flex items-center justify-between  ">
                      <p className="text-ellipsis whitespace-nowrap leading-4 sm:text-xs mm:text-sm md:hidden">
                        {truncate(eventAddress, { length: 20 })}
                      </p>
                      <div className="flex items-center gap-2">
                        <a
                          className="group mb-1 flex items-center justify-start gap-2"
                          target="_blank"
                          rel="noreferrer"
                          href={`https://www.google.com/maps/place/${location[1]},${location[0]}`}
                        >
                          <p className="font-semibold leading-4 tracking-tight text-btnPrimary sm:text-xs mm:text-sm">
                            Get Direction
                          </p>
                          <ArrowCircleRightIcon className="size-6 text-btnPrimary" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between rounded-lg border border-gray-200 p-3 md:p-4">
                    <p className="flex flex-row items-center justify-center text-nowrap font-semibold leading-4 text-btnPrimary sm:text-xs mm:text-sm">
                      Share this Event <ArrowCircleRightIcon className="text-btnPrimary sm:size-3 mm:size-6" />
                    </p>
                    <div className="flex gap-2">
                      <div onClick={handleShare}>
                        {' '}
                        <BsInstagram className="text-[#8a3ab9] sm:size-4 mm:size-6" />
                      </div>
                      <div onClick={handleShare}>
                        {' '}
                        <BsFacebook className="text-[#4267B2] sm:size-4 mm:size-6" />
                      </div>
                      <div onClick={handleShare}>
                        {' '}
                        <BsWhatsapp className="text-[#25D366] sm:size-4 mm:size-6" />
                      </div>
                    </div>
                  </div>
                  {artistName && artistDescription && (
                    <div className="flex flex-col ">
                      <div className="relative rounded-t-xl">
                        <CTImage
                          className="rounded-t-xl"
                          src={artistImageUrl && artistImageUrl}
                          alt={artistName && artistName}
                        />
                        <div className="absolute inset-x-0 bottom-0 h-16 bg-gradient-to-t from-gray-800 to-transparent" />
                        <div className="absolute bottom-0 left-0 p-4 text-lg font-bold leading-6 text-white">
                          {artistName && artistName}
                        </div>
                      </div>

                      <div className="flex-col items-start rounded-b-xl bg-[#101828] p-4 text-white">
                        <div className="font-normal tracking-wide text-white sm:text-xs mm:text-sm">
                          {showFullText
                            ? parse(artistDescription)
                            : parse(artistDescription && artistDescription.slice(0, artistDescription.length / 2))}
                        </div>
                        {artistDescription && artistDescription.length > artistDescription.length / 2 && (
                          <p
                            className="cursor-pointer tracking-tight text-white underline sm:text-xs mm:text-sm"
                            onClick={toggleReadMore}
                          >
                            {showFullText ? 'Read Less' : 'Read More'}
                          </p>
                        )}
                        <div className="flex items-center gap-4 pt-1">
                          <p className="font-semibold sm:text-xs mm:text-sm">
                            Follow {truncate(artistName, { length: 7 })} on
                          </p>
                          <div className="flex items-center gap-2">
                            <a href={artistInstaUrl && artistInstaUrl}>
                              <BsInstagram className="sm:size-4 mm:size-6" />
                            </a>
                            <a href={artistFacebookUrl && artistFacebookUrl}>
                              <BsFacebook className="sm:size-4 mm:size-6" />
                            </a>
                            <a href={artistTwitterUrl && artistTwitterUrl}>
                              <BsTwitter className="sm:size-4 mm:size-6" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="flex flex-col gap-3 text-left md:flex-row">
                {coupons &&
                  coupons.length > 0 &&
                  coupons.map((item, index) => (
                    <div
                      key={index}
                      className="flex w-full  flex-col  rounded-lg bg-rose-50
                   p-2 shadow-xl md:w-[400px] lg:p-4"
                      //style={{ backgroundImage: `url(${fgImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}
                    >
                      <div className="flex flex-row">
                        <RiCoupon3Fill className="mr-4 size-10 rounded-full bg-btnPrimary p-2 text-white" />
                        <div className="flex w-[80%] flex-col gap-1">
                          <p className="w-[95%] text-wrap text-sm font-semibold text-[#222222] hover:text-btnPrimary ">
                            {item?.name}
                          </p>
                          <div className="tracking-wide text-[#475467] sm:text-xs">
                            <button
                              onClick={() => handleGetTNCClick(item?.desc, item?.tnc)}
                              className="text-xs font-medium text-rose-600"
                            >
                              {truncate(item?.desc, { length: 40 })} More
                            </button>
                          </div>
                        </div>
                        <div className="item-center flex w-[20%] justify-center">
                          <button
                            className=" cursor-pointer border-l-2 border-dotted border-rose-600 pl-3 text-sm font-semibold text-rose-600 md:right-0"
                            onClick={() => copyCouponCode(item?.code)}
                          >
                            COPY
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                {showPopup && <TNCCoupon desc={selectedDesc} tnc={selectedTNC} handleClose={handleClosePopup} />}

                {popupVisible && (
                  <div className="mx-auto">
                    <div className="fixed inset-x-0 top-20 mx-auto w-[300px] items-center justify-center rounded-md bg-btnPrimary p-3 text-center text-white shadow-md">
                      Coupon code copied to clipboard!
                    </div>
                  </div>
                )}
              </div> */}

              <div className="flex flex-col gap-2 text-left ">
                <p className="text-lg font-bold leading-7 text-[#222222] hover:text-btnPrimary lg:text-xl ">Details</p>
                <div className="leading-6 tracking-wide text-[#475467] sm:text-xs mm:text-sm lg:text-base">
                  <div>{showFullText ? parse(description) : parse(description && description.slice(0, 2000))}</div>
                  {description && description.length > 2000 && (
                    <p
                      className="cursor-pointersm:text-xs tracking-tight text-btnPrimary mm:text-sm lg:text-base"
                      onClick={toggleReadMore}
                    >
                      {showFullText ? 'Read Less' : 'Read More'}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-3 text-left">
                {eventpolicy.map((item, index) => (
                  <div key={index}>
                    <p className="mb-2 text-lg font-bold leading-7 text-[#222222] hover:text-btnPrimary lg:text-xl ">
                      {item?.title}
                    </p>
                    {item?.description && Array.isArray(item?.description) ? (
                      Array.from(item?.description).map((descItem, descIndex) => (
                        <div
                          className="leading-6 tracking-wide text-[#475467] sm:text-xs mm:text-sm lg:text-base"
                          key={descIndex}
                        >
                          {parse(descItem)}
                        </div>
                      ))
                    ) : (
                      <div className="mb-2 leading-6 tracking-wide text-[#475467] sm:text-xs mm:text-sm lg:text-base">
                        {showFT[index]
                          ? parse(item?.description)
                          : parse(item?.description && item?.description.slice(0, 2000))}
                        {item?.description && item?.description.length > 2000 && (
                          <p
                            className="cursor-pointer tracking-tight text-btnPrimary sm:text-xs mm:text-sm lg:text-base"
                            onClick={() => toggleRM(index)}
                          >
                            {showFT[index] ? 'Read Less' : 'Read More '}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden sm:w-full md:block md:w-[330px] lg:w-[437px]">
            <div
              className={`text-inter overflow-y-hidden' } flex  flex-col
            gap-4`}
            >
              <div className="flex flex-col gap-4 rounded-lg border border-gray-200 md:p-3 lg:p-4 ">
                <p className="text-2xl font-bold leading-8 tracking-wide text-[#101828]">{name && name}</p>
                <div className="mx-2 border-b  border-gray-200" />
                <div className="mb-2 grid grid-cols-2 gap-4">
                  {duration && <Info text={duration && duration} icon={<TbTimeDuration30 />} />}
                  {eventTime && <Info text={eventTime && eventTime} icon={<PiClock />} />}
                  {dateString && <Info text={dateString && dateString} icon={<GrCalendar />} />}
                  {contentType && <Info text={contentType && contentType} icon={<GrUser />} />}
                  {language && <Info text={language && language} icon={<GrLanguage />} />}
                  {categoryDisplay && <Info text={categoryDisplay && categoryDisplay} icon={<BiCategory />} />}
                </div>
                {subdescription && (
                  <div className="text-sm leading-5 tracking-wider text-secondary">{parse(subdescription)}</div>
                )}

                {/* for large screen */}
                <button
                  className="hidden w-full items-center justify-between rounded-3xl bg-btnPrimary bg-cover px-4 py-2 text-white md:flex"
                  onClick={handleGetTicketsClick}
                  style={buttonStyle}
                >
                  <div className="flex flex-col items-start justify-start">
                    <p className="text-xs font-semibold text-[#FFA9A9] lg:text-sm">STARTING FROM</p>
                    <p className="text-nowrap font-semibold uppercase leading-5 tracking-tight md:text-sm lg:text-base">
                      {ticketPriceDisplay && ticketPriceDisplay}
                    </p>
                  </div>
                  <div className="flex items-center gap-2 md:pl-2 lg:pl-0">
                    <p className="text-nowrap font-medium leading-5 text-white md:text-xs lg:text-base"> Get Tickets</p>
                    <ArrowCircleRightIcon className="size-6  text-white" />
                  </div>
                </button>
                {event?.bookingDone && (
                  <button
                    className="hidden w-full items-center justify-center rounded-3xl bg-btnPrimary bg-cover px-4 py-2 text-center text-white md:flex"
                    onClick={handleViewTicket}
                    style={buttonStyle}
                  >
                    {home.button3}
                  </button>
                )}
              </div>

              <div className="flex flex-col gap-4 rounded-lg border border-gray-200 p-4">
                <LocationMap location={location} />

                <div className="flex items-center justify-between  ">
                  <p className="text-ellipsis whitespace-nowrap text-sm leading-4 lg:hidden">
                    {truncate(eventAddress, { length: 15 })}
                  </p>
                  <p className="hidden text-ellipsis whitespace-nowrap text-[0.8rem] lg:flex xl:hidden">
                    {truncate(eventAddress, { length: 25 })}
                  </p>
                  <p className="hidden text-ellipsis whitespace-nowrap text-[0.8rem] xl:flex">
                    {truncate(eventAddress, { length: 30 })}
                  </p>

                  <div className="flex items-center">
                    <a
                      className="group mb-1 flex items-center justify-start gap-2"
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.com/maps/place/${location[1]},${location[0]}`}
                    >
                      <p className="text-sm font-semibold leading-4 tracking-tight text-btnPrimary">Get Direction</p>
                      <ArrowCircleRightIcon className="size-6 text-btnPrimary" />
                    </a>
                  </div>
                </div>
              </div>
              {artistName && artistDescription && (
                <div className="flex flex-col ">
                  <div className="relative rounded-t-xl">
                    <CTImage
                      className="rounded-t-xl "
                      src={artistImageUrl && artistImageUrl}
                      alt={artistName && artistName}
                    />
                    <div className="absolute inset-x-0 bottom-0 h-16 bg-gradient-to-t from-gray-800 to-transparent" />
                    <div className="absolute bottom-0 left-0 p-4 text-xl font-bold leading-6 text-white lg:text-2xl">
                      {artistName && artistName}
                    </div>
                  </div>

                  <div className="flex-col items-start rounded-b-xl bg-[#101828] p-4 text-white">
                    <div className="text-xs font-normal text-white lg:text-sm">
                      {showFullText
                        ? parse(artistDescription)
                        : parse(artistDescription && artistDescription.slice(0, 200))}
                    </div>
                    {artistDescription && artistDescription.length > 200 && (
                      <p className="cursor-pointer text-xs text-white underline lg:text-sm" onClick={toggleReadMore}>
                        {showFullText ? 'Read Less' : 'Read More'}
                      </p>
                    )}
                    <div className="flex items-center gap-4 pt-1">
                      <p className=" text-sm font-semibold">Follow {truncate(artistName, { length: 15 })} on</p>
                      <div className="flex items-center gap-2">
                        <a href={artistInstaUrl && artistInstaUrl}>
                          <BsInstagram className="size-6" />
                        </a>
                        <a href={artistFacebookUrl && artistFacebookUrl}>
                          <BsFacebook className="size-6" />
                        </a>
                        <a href={artistTwitterUrl && artistTwitterUrl}>
                          <BsTwitter className="size-6" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex items-center justify-between rounded-lg border border-gray-200 p-3 lg:p-4">
                <p className="flex flex-row items-center justify-center text-nowrap text-sm font-semibold leading-4 text-btnPrimary lg:text-base">
                  Share this Event <ArrowCircleRightIcon className="size-6 text-btnPrimary " />
                </p>
                <div className="flex gap-2">
                  <div onClick={handleShare}>
                    {' '}
                    <BsInstagram className="size-6 text-[#8a3ab9]" />
                  </div>
                  <div onClick={handleShare}>
                    {' '}
                    <BsFacebook className="size-6 text-[#4267B2]" />
                  </div>
                  <div onClick={handleShare}>
                    {' '}
                    <BsWhatsapp className="size-6 text-[#25D366]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CTWrapper>
      <Checkout handleOpen={handleOpen} isOpen={isCheckoutOpen} handleClose={() => setIsCheckoutOpen(false)} />
    </>
  );
};
const mapStateToProps = (state) => ({
  event: get(state, 'auth.event', {}),
  eventInfo: get(state, 'event.eventInfo', {}),
});
export default connect(mapStateToProps)(memo(BookHeader));
